<template>
  <div class="header-container">
    <div class="logo-wrap">
      <img class="header-logo" src="@/assets/images/header/logo-white.png" />
      <span class="header-title">智慧农场</span>
    </div>
    <div class="header-tabs">
      <el-menu
        :default-active="menuIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="#171e49"
        text-color="rgba(225, 225, 225, 0.5)"
        active-text-color="#fff"
        :unique-opened="true"
        :router="true"
      >
        <div
          style="display: inline-block"
          v-for="list in menuData"
          :key="list.index"
        >
          <el-menu-item
            v-if="!list.children.length && list.show"
            :index="list.index"
            :route="list.link"
          >
            <span slot="title">{{ list.name }}</span>
          </el-menu-item>
          <el-submenu
            :index="list.index"
            v-if="list.children.length && list.show"
          >
            <template slot="title">
              <span class="itemS_title">{{ list.name }}</span>
            </template>
            <span v-for="item in list.children" :key="item.index">
              <el-menu-item
                class="el_menu_item"
                v-if="item.show"
                :index="item.index"
                :route="item.link"
              >
                {{ item.name }}
              </el-menu-item>
            </span>
          </el-submenu>
        </div>
      </el-menu>
    </div>
    <div class="user-container">
      <!-- <img
        class="user-container_notification"
        src="@/assets/images/header/notification.png"
      /> -->
      <span></span>
      <div class="user-name">
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            {{ userInfo.name }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">修改密码</el-dropdown-item>
            <el-dropdown-item command="2">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuIndex: String,
  },
  data() {
    return {
      userInfo: JSON.parse(window.sessionStorage.getItem("userInfo")),
      menuData: [
        {
          index: "1",
          name: "设备管理",
          link: "/home",
          url: "devices",
          show: false,
          children: [],
        },
        {
          index: "2",
          name: "系统管理",
          link: "/manage",
          url: "system",
          show: false,
          children: [
            {
              index: "2-1",
              name: "用户管理",
              url: "system-user",
              link: "/userManage",
              show: false,
            },
            {
              index: "2-2",
              name: "权限管理",
              url: "system-auth",
              link: "/authManage",
              show: false,
            },
            {
              index: "2-3",
              name: "角色管理",
              url: "system-role",
              link: "/roleManage",
              show: false,
            },
            {
              index: "2-4",
              name: "园区地块",
              url: "system-dept",
              link: "/deptManage",
              show: false,
            },
          ],
        },
      ],
    };
  },
  created() {
    let {
      userInfo: { permissionList: authArr },
      menuData,
    } = this;
    for (let i = 0; i < this.menuData.length; i++) {
      for (let x = 0; x < authArr.length; x++) {
        if (this.menuData[i].url === authArr[x].url) {
          this.menuData[i].show = true;
        }
        let child = this.menuData[i].children;
        if (child.length) {
          for (let y = 0; y < child.length; y++) {
            if (child[y].url === authArr[x].url) {
              child[y].show = true;
            }
          }
        }
      }
    }
  },

  methods: {
    handleCommand(i) {
      switch (i) {
        case "1":
          this.$router.push({ path: "/changePassword" });
          break;
        case "2":
          sessionStorage.clear();
          this.$router.push({ path: "/" });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-submenu__icon-arrow {
  right: 0 !important;
  margin-top: -5px !important;
}
/deep/.el-menu {
  border-bottom: 0 !important;
  .el-menu-item {
    padding: 0 28px !important;
    border-bottom: 0 !important;
    &:hover {
      color: rgba(225, 225, 225, 1) !important;
    }
  }
  .el-submenu {
    .el-submenu__title {
      border-bottom: 0 !important;
      &:hover {
        color: rgba(225, 225, 225, 1) !important;
      }
    }
  }
}
/deep/.el-select {
  .el-input__inner {
    color: #1677ff !important;
    border-color: transparent !important;
    background-color: transparent !important;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
}
.el-icon-arrow-down {
  font-size: 14px;
}
</style>
<style lang="scss" scoped>
.el_menu_item {
  height: 50px !important;
  padding: 0 20px !important;
  line-height: 50px !important;
}
.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 30px;
  box-sizing: border-box;
  background-color: #171e49;
  .logo-wrap {
    display: flex;
    align-items: center;
    width: 238px;
    .header-logo {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    .header-title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }

  .header-tabs {
    width: 74%;
    padding: 0 30px;
    text-align: center;
  }
  .user-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 185px;
    .user-container_notification {
      width: 25px;
      height: 20px;
    }
    .user-name {
      display: flex;
      .user-name_text {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
</style>
